import labicon from '../assets/images/icons/lab.svg';
import eyeicon from '../assets/images/icons/eye.svg';
import chaticon from '../assets/images/icons/interview.svg';
import thinkicon from '../assets/images/icons/brain.svg';
import sprinticon from '../assets/images/icons/time.svg';
import modelicon from '../assets/images/icons/model.svg';

import labiconmail from '../assets/images/icons/lab.png';
import eyeiconmail from '../assets/images/icons/eye.png';
import chaticonmail from '../assets/images/icons/interview.png';
import thinkiconmail from '../assets/images/icons/brain.png';
import sprinticonmail from '../assets/images/icons/time.png';
import modeliconmail from '../assets/images/icons/model.png';

export const PACKAGES = [
    {
        id: 1,
        title: "Ux lab standaard",
        icon: labicon,
        iconmail: labiconmail,
        days: 1,
        days_suffix: "+ dagen",
        price: 1850,
        price_suffix: "+",
        description: "Het Ux lab in de standaard configuratie, uitbreidbaar naar de specifieke wensen van je team.",
        recommended: "Instructeur / technisch support",
        list:
            {
                "A/V recording": true,
                "BMC Template setup": false,
                "Creative walls": true,
                "Catering": false,
                "Eyetrack": true,
                "Eyetrack voor web & app": false,
                "Facial coding": false,
                "Interviewsetup": false,
                "User Research specialist als begeleider": false,
                "Host / hostess": true,
                "Technische ondersteuning": true
            }
    },
    {
        id: 2,
        title: "Eye-tracking",
        icon: eyeicon,
        iconmail: eyeiconmail,
        days: 2,
        days_suffix: " dagen",
        price: 4700,
        description: "Test je product met onze gloednieuwe eye-tracking setup en ga naar huis met een koffer vol insights.",
        recommended: "Design Thinking strateeg (gecertificeerd)",
        list:
            {
                "A/V recording": true,
                "BMC Template setup": false,
                "Creative walls": false,
                "Catering": true,
                "Eyetrack": false,
                "Eyetrack voor web & app": true,
                "Facial coding": true,
                "Interviewsetup": false,
                "User Research specialist als begeleider": true
            }
    },
    {
        id: 3,
        title: "Interviews",
        icon: chaticon,
        iconmail: chaticonmail,
        days: 1,
        days_suffix: " dag",
        price: 2000,
        description: "De beste kwalitatieve inzichten bij je doelgroep doe je op met interviews. Onze interviewsetup helpt je hier het meest uit te halen.",
        recommended: "Instructeur / technisch support",
        list:
            {
                "A/V recording": true,
                "BMC Template setup": false,
                "Creative walls": true,
                "Catering": false,
                "Eyetrack": false,
                "Eyetrack voor web & app": false,
                "Facial coding": false,
                "Interviewsetup": true,
                "User Research specialist als begeleider": false
            }
    },
    {
        id: 4,
        title: "Design Thinking Session",
        icon: thinkicon,
        iconmail: thinkiconmail,
        days: 2,
        days_suffix: " dagen",
        price: 4800,
        description: "Introduceer je team met Design Thinking: werk in een begeleide worskhopsetting naar een nieuwe methode om naar je uitdagingen te kijken.",
        recommended: "Design Thinking strateeg (gecertificeerd)",
        list:
            {
                "A/V recording": true,
                "BMC Template setup": false,
                "Creative walls": false,
                "Catering": true,
                "Eyetrack": true,
                "Eyetrack voor web & app": false,
                "Facial coding": true,
                "Interviewsetup": false,
                "User Research specialist als begeleider": false
            }
    },
    {
        id: 5,
        title: "Designsprint",
        icon: sprinticon,
        iconmail: sprinticonmail,
        days: 5,
        days_suffix: " dagen",
        price: 10000,
        description: "Van inzichten naar een werkend prototype in 5 dagen? De designsprint-configuratie is ingericht op maximale creativiteit en inspiratie.",
        recommended: "Instructeur / technisch support",
        list:
            {
                "A/V recording": true,
                "BMC Template setup": false,
                "Creative walls": true,
                "Catering": true,
                "Eyetrack": false,
                "Eyetrack voor web & app": false,
                "Facial coding": false,
                "Interviewsetup": false,
                "User Research specialist als begeleider": false
            }
    },
    {
        id: 6,
        title: "Business Model Canvassing",
        icon: modelicon,
        iconmail: modeliconmail,
        days: 1,
        days_suffix: " dag",
        price: 1600,
        description: "Breng alle elementen van je business in beeld met het BMC. Doe het zelf of laat ons je helpen!",
        recommended: "Design Thinking strateeg (gecertificeerd)",
        list:
            {
                "A/V recording": false,
                "BMC Template setup": true,
                "Creative walls": false,
                "Catering": true,
                "Eyetrack": false,
                "Eyetrack voor web & app": false,
                "Facial coding": false,
                "Interviewsetup": false,
                "User Research specialist als begeleider": false
            }
    }
];