import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import classnames from 'classnames';
import SVG from 'react-inlinesvg';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {selectedPackage} from "../actions";
import {Desktop, Mobile} from '../components/Responsive';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Configurator from "../components/Configurator";
import Datepicker from "../components/Datepicker";
import DatepickerButton from '../components/DatepickerButton'
import logo from '../assets/images/logos/logo.svg';
import logopowered from "../assets/images/logos/logo_powered.svg";
import scrollicon from '../assets/images/icons/scroll.svg';
import settingicon from '../assets/images/icons/settings.svg';
import arrowdownicon from '../assets/images/icons/arrow-down.svg';
import arrowrighticon from "../assets/images/icons/arrow-right.svg";
import plusicon from '../assets/images/icons/plus.svg';
import insight1 from "../assets/images/shapes/insight1.svg";
import insight2 from "../assets/images/shapes/insight2.svg";
import insight3 from "../assets/images/shapes/insight3.svg";
import insight4 from "../assets/images/shapes/insight4.svg";
import insight5 from "../assets/images/shapes/insight5.svg";
import insight6 from "../assets/images/shapes/insight6.svg";
import insight7 from "../assets/images/shapes/insight7.svg";
import insight8 from "../assets/images/shapes/insight8.svg";
import insight9 from "../assets/images/shapes/insight9.svg";
import research1 from "../assets/images/research1.png";
import research2 from "../assets/images/research2.png";
import bottom1 from "../assets/images/shapes/bottom1.svg";
import bottom2 from "../assets/images/shapes/bottom2.svg";
import bottom3 from "../assets/images/shapes/bottom3.svg";
import bottom4 from "../assets/images/shapes/bottom4.svg";
import bottom5 from "../assets/images/shapes/bottom5.svg";
import bottom6 from "../assets/images/shapes/bottom6.svg";
import bottom7 from "../assets/images/shapes/bottom7.svg";
import bottom8 from "../assets/images/shapes/bottom8.svg";
import bottom9 from "../assets/images/shapes/bottom9.svg";
import bottom10 from "../assets/images/shapes/bottom10.svg";
import bottom11 from "../assets/images/shapes/bottom11.svg";
import bottom12 from "../assets/images/shapes/bottom12.svg";
import bottom13 from "../assets/images/shapes/bottom13.svg";
import bottom14 from "../assets/images/shapes/bottom14.svg";
import bottom15 from "../assets/images/shapes/bottom15.svg";
import bottom16 from "../assets/images/shapes/bottom16.svg";

class Home extends Layout {
    constructor(props) {
        super(props);
        this.showHeader = false;
        this.showSidebar = false;
        this.dateRef = React.createRef();
        this.cfgRef = React.createRef();
        this.state = {
            config: false,
            datepicker: false,
            mobileMenu: false,
            mobileCfg: false,
            mobileCfgChoosen: false,
            dateref: false,
            cfgref: false
        };
    }
    
    componentDidMount() {
        super.componentDidMount(window.addEventListener("scroll", this.modalClose));
        document.addEventListener("mouseup", this.handleClickOutside);
    }
    
    componentWillUnmount() {
        super.componentWillUnmount(window.removeEventListener("scroll", this.modalClose));
        document.removeEventListener("mouseup", this.handleClickOutside);
    }
    
    handleClickOutside = e => {
        if (this.state.datepicker) {
            if (!this.dateRef.current.contains(e.target)) {
                this.setState({datepicker: false, dateref: true});
                setTimeout(() => {
                    this.setState({dateref: false});
                }, 10);
            }
        }
        if (this.state.config) {
            if (!this.cfgRef.current.contains(e.target)) {
                this.setState({config: false, cfgref: true});
                setTimeout(() => {
                    this.setState({cfgref: false});
                }, 10);
            }
        }
    };
    
    modalClose = () => {
        this.setState({
            config: false,
            datepicker: false
        })
    };
    
    cfgClick = () => {
        if (this.state.cfgref) {
            this.setState({
                config: false,
                datepicker: false
            });
        } else {
            this.setState({
                config: !this.state.config,
                datepicker: false
            });
        }
        
    };
    
    datepickerOpen = () => {
        this.setState({
            datepicker: true,
            config: false
        });
    };
    
    datepickerClick = () => {
        if (this.state.dateref) {
            this.setState({
                config: false,
                datepicker: false
            });
        } else {
            this.setState({
                datepicker: !this.state.datepicker,
                config: false
            });
        }
    };
    
    mobileClick = () => {
        this.setState({
            mobileMenu: !this.state.mobileMenu,
        });
    };
    
    mobileCfgClick = () => {
        this.setState({
            mobileCfg: !this.state.mobileCfg,
        });
    };
    
    mobileCfgChoosen = () => {
        this.setState({
            mobileCfg: !this.state.mobileCfg,
            mobileCfgChoosen: true
        });
    };
    
    renderMobileMenu() {
        const {selectedPackage, cookies} = this.props;
        const {mobileCfgChoosen, mobileMenu} = this.state;
        
        return (
            <div className={classnames("mobile__menu", {"mobile__menu-active": mobileMenu})}>
                <div className="mobile__menu__wrapper">
                    <div className="mobile__menu__container">
                        <div className="mobile__menu__header">
                            <div onClick={this.mobileClick}>
                                <AnchorLink href="#block__top">
                                    <SVG
                                        className='mobile__menu__header-logo'
                                        src={logo}
                                        uniquifyIDs
                                        cacheGetRequests
                                    />
                                </AnchorLink>
                            </div>
                            <div onClick={this.mobileClick}>
                                <SVG
                                    className='mobile__menu__header-close'
                                    src={plusicon}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                            </div>
                        </div>
                        <div className="mobile__menu__content">
                            <div
                                className={classnames("mobile__menu__link", {"mobile__menu__link-choosen": mobileCfgChoosen})}
                                onClick={this.mobileCfgClick}>
                                {cookies.get('selectedPackageTitle') || selectedPackage.title} configuratie
                                <SVG
                                    className='mobile__menu__link-icon'
                                    src={arrowrighticon}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                            </div>
                            
                            <div className="mobile__menu__content-item">
                                <Datepicker cookies={cookies} numberOfMonths={1} daySize={35} btnText='Kies'/>
                            </div>
                        </div>
                        {this.renderMobileCfg()}
                    </div>
                </div>
            </div>
        );
    }
    
    renderMobileCfg() {
        const {selectedPackage, cookies} = this.props;
        const {mobileCfg} = this.state;
        return (
            <div className={classnames("mobile__menu__cfg", {"mobile__menu__cfg-active": mobileCfg})}>
                <div className="mobile__menu__cfg__wrapper">
                    <div className="mobile__menu__cfg__header">
                        <div>
                            {cookies.get('selectedPackageTitle') || selectedPackage.title} configuratie
                        </div>
                        <div onClick={this.mobileCfgClick}>
                            <SVG
                                className='mobile__menu__header-close'
                                src={plusicon}
                                uniquifyIDs
                                cacheGetRequests
                            />
                        </div>
                    </div>
                    <div className="mobile__menu__cfg__content-item">
                        <Configurator cookies={cookies} mobileclick={this.mobileCfgChoosen} uniquekey="mobile"/>
                    </div>
                </div>
            </div>
        );
    }
    
    renderDatePicker(screen, className) {
        switch (screen) {
            case "mobile": {
                return <Mobile><DatepickerButton className={className} click={this.mobileClick}/></Mobile>;
            }
            case "desktop": {
                return <Desktop><DatepickerButton className={className} click={this.datepickerClick}/></Desktop>;
            }
            default:
                return ([
                    <Desktop key='desktop'>
                        <DatepickerButton className={className} click={this.datepickerClick}/>
                    </Desktop>,
                    <Mobile key='mobile'>
                        <DatepickerButton className={className} click={this.mobileClick}/>
                    </Mobile>
                ]);
        }
    }
    
    renderPageContent(prevScrollpos) {
        const {config, datepicker} = this.state;
        const {selectedPackage, cookies} = this.props;
        return (
            <div className="home">
                <div id="block__top" className={classnames("block__top", {"block__top-hidden": prevScrollpos > 0})}>
                    <Hero animate={!prevScrollpos > 0}>
                        {this.renderDatePicker('mobile', 'hero-btn')}
                        <Desktop>
                            <div className="scroll" href="#block__insight" onClick={() => window.scrollTo(0, 1,)}>
                                <SVG
                                    className='scroll-icon-svg'
                                    src={scrollicon}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                            </div>
                        </Desktop>
                    </Hero>
                    <a href="https://concept7.nl/" target="_blank" rel="noopener noreferrer">
                        <SVG
                            className='hero-logo-powered'
                            src={logopowered}
                            uniquifyIDs
                            cacheGetRequests
                        />
                    </a>
                
                </div>
                {/*<Desktop>*/}
                <div className={classnames("settings", {"settings-sticky": prevScrollpos > 0})}>
                    <div className="settings__block-wrapper">
                        <div className="settings__block settings__block-logo">
                            
                            <AnchorLink href="#block__top">
                                <SVG
                                    className='settings__block-logo-svg'
                                    src={logo}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                            </AnchorLink>
                        </div>
                        <div className="settings__block settings__block-reserve">
                            Reserveer nu
                        </div>
                        <div
                            className={classnames("settings__block settings__block-config", {"settings__block-config-active": config})}
                            onClick={this.cfgClick}>
                            <div className="settings__block-config-icon">
                                <SVG
                                    className='settings__block-config-svg'
                                    src={settingicon}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                            </div>
                            {cookies.get('selectedPackageTitle') ?
                                cookies.get('selectedPackageTitle') === 'Ux lab standaard' ?
                                    'Standaard configuratie' :
                                    `${cookies.get('selectedPackageTitle')} configuratie` :
                                selectedPackage.title === 'Ux lab standaard' ?
                                    'Standaard configuratie' :
                                    `${selectedPackage.title} configuratie`
                            }
                        </div>
                        <div className="settings__block settings__block-datepicker">
                            <DatepickerButton click={this.datepickerClick}/>
                        </div>
                    </div>
                    <div
                        className={classnames("cfg__option-modal", {"active": config}, prevScrollpos > 0 ? "cfg__option-modal-bottom" : "cfg__option-modal-top")}>
                        <div className="container" ref={this.cfgRef}>
                            <div className="row">
                                <Configurator cookies={cookies} desktopclick={this.datepickerOpen} uniquekey="desktop"/>
                            </div>
                            <div className="cfg__link-wrapper">
                                <AnchorLink className="cfg__link" href="#extconfig"
                                            offset={prevScrollpos === 0 ? '1200' : '0'}>
                                    Meer weten over de configuraties?
                                    <SVG
                                        className='cfg__link-icon'
                                        src={arrowdownicon}
                                        uniquifyIDs
                                        cacheGetRequests
                                    />
                                </AnchorLink>
                            </div>
                        </div>
                        <div className="triangle"/>
                    </div>
                    <div
                        className={classnames("datepicker__modal", {"active": datepicker}, prevScrollpos > 0 ? "datepicker__modal-bottom" : "datepicker__modal-top")}>
                        <div className="datepicker__wrapper" ref={this.dateRef}>
                            <Datepicker cookies={cookies}/>
                        </div>
                        <div className="triangle"/>
                    </div>
                </div>
                {/*</Desktop>*/}
                <div className="block__insight" id="block__insight">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6 block__insight-left">
                                <SVG
                                    className='block__insight-left-insight-1'
                                    src={insight7}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    className='block__insight-left-insight-2'
                                    src={insight8}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    className='block__insight-left-insight-3'
                                    src={insight9}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    className='block__insight-left-insight-4'
                                    src={insight1}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <div className="block__text">
                                    <h1>Inzicht meets inspiratie</h1>
                                    <p>Goede ideëen beginnen bij goede research. Het Ux lab brengt de beste
                                        onderzoeksmethodes samen in een inspirerende setting voor je hele team waarin
                                        alles draait om inzicht en creativiteit.</p>
                                    <p>Van interviews, eyetracking en user journeys tot volledige designsprints
                                        faciliteert het Ux lab vanuit haar uitgebreide ervaring de beste oplossing voor
                                        jouw team en product.</p>
                                    {this.renderDatePicker()}
                                </div>
                            </div>
                            <div className="col-sm-6 block__insight-right">
                                <SVG
                                    className='block__insight-right-insight-1'
                                    src={insight1}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    style={{marginTop: `-${prevScrollpos}px`}}
                                    className='block__insight-right-insight-2'
                                    src={insight2}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    style={{marginTop: `-${prevScrollpos}px`}}
                                    className='block__insight-right-insight-3'
                                    src={insight3}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    className='block__insight-right-insight-4'
                                    src={insight4}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    style={{marginTop: `-${prevScrollpos}px`}}
                                    className='block__insight-right-insight-5'
                                    src={insight5}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    style={{marginTop: `-${prevScrollpos}px`}}
                                    className='block__insight-right-insight-6'
                                    src={insight6}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    style={{marginTop: `-${prevScrollpos}px`}}
                                    className='block__insight-right-insight-7'
                                    src={insight7}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    className='block__insight-right-insight-8'
                                    src={insight8}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                                <SVG
                                    style={{marginTop: `-${prevScrollpos}px`}}
                                    className='block__insight-right-insight-9'
                                    src={insight9}
                                    uniquifyIDs
                                    cacheGetRequests
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block__research">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6 block__research-left">
                                <img src={research1} alt="research1" className="img-responsive"/>
                                <Desktop>
                                    <img src={research2} alt="research2"/>
                                </Desktop>
                            </div>
                            <div className="col-12 col-sm-6 block__research-right">
                                <div className="block__text">
                                    <h2>Ingericht voor optimaal onderzoek</h2>
                                    <p>Het Ux lab is ontstaan uit onze uitgebreide kennis over wat wel en niet werkt in
                                        gebruikersonderzoek en verder.</p>
                                    <p>Daarom hebben wij het Ux lab ingericht voor de meest optimalize ervaring voor
                                        elke gebruikersvraag: van eyetracking onderzoek tot volledige designsprints
                                        hebben wij alles tot de puntjes ingericht voor het beste resultaat.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block__cfg">
                    <div className="block__cfg-shape-top" id="extconfig"/>
                    <div className="block__cfg-title">Kies je configuratie</div>
                    <div className="container">
                        <div className="row">
                            <Configurator extended={true} cookies={cookies} desktopclick={this.datepickerOpen}
                                          mobileclick={this.mobileClick} key="extended"/>
                        </div>
                    </div>
                    <div className="block__cfg-shape-bottom"/>
                </div>
                <div className="block__bottom">
                    <SVG
                        className='block__bottom-logo'
                        src={logo}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    {this.renderDatePicker()}
                    <SVG
                        className='block__bottom-shape-1'
                        src={bottom1}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    <SVG
                        className='block__bottom-shape-2'
                        src={bottom2}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    <SVG
                        className='block__bottom-shape-3'
                        src={bottom3}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    <SVG
                        className='block__bottom-shape-4'
                        src={bottom4}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    <SVG
                        className='block__bottom-shape-5'
                        src={bottom5}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    <SVG
                        className='block__bottom-shape-6'
                        src={bottom6}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    <Desktop>
                        <SVG
                            className='block__bottom-shape-7'
                            src={bottom7}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-8'
                            src={bottom8}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-9'
                            src={bottom9}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-10'
                            src={bottom10}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-11'
                            src={bottom11}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-12'
                            src={bottom12}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-13'
                            src={bottom13}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-14'
                            src={bottom14}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-15'
                            src={bottom15}
                            uniquifyIDs
                            cacheGetRequests
                        />
                        <SVG
                            className='block__bottom-shape-16'
                            src={bottom16}
                            uniquifyIDs
                            cacheGetRequests
                        />
                    </Desktop>
                </div>
                {this.renderMobileMenu()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selectedPackage: state.selectedPackage,
    cookies: ownProps.cookies,
});

const mapDispatchToProps = dispatch => bindActionCreators({selectPackage: selectedPackage}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);