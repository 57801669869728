import React from 'react';
import Layout from '../components/Layout';
import Sidebar from "../components/Sidebar";

class PrivacyPolicy extends Layout {
				constructor(props) {
								super(props);
								this.headerTitle = "Privacy Policy";
				}
				
				renderSidebar() {
								return (
												<Sidebar/>
								);
				}
				
				renderPageContent() {
								return (
												<div className="privacy content-container">
																<div className="privacy__content">
																				<div className="privacy__content__block">
																								<h1>
																												Privacy statement Ux lab
																								</h1>
																								<p>
																												Ux lab is een initiatief van Concept7 en is ontwikkeld om organisatie te kunnen ondersteunen in hun creatieve proces.
																								</p>
																								<p>
																												Privacy is voor Ux lab en voor Concept7 van groot belang. We onderzoeken en faciliteren immers onderzoek naar gedrag van mensen, voor organisaties die willen innoveren en optimaliseren. We willen een veilige omgeving creëren waarin in alle openheid kan worden geleerd en gedeeld.
																								</p>
																								<p>
																												Dit privacy statement geeft weer hoe we omgaan met de informatie die we verzamelen als je onze faciliteiten gebruikt. Door gebruik te maken van onze dienstverlening gaat je akkoord met ons privacy protocol en onze algemene voorwaarden. Mocht je vragen hebben hierover, mail ons dan even op <a href="mailto:info@uxlab.nl">info@Uxlab.nl</a>.
																								</p>
																								<p>
																												<strong>Persoonlijke en zakelijke op de persoon herleidbare informatie:</strong><br/>
																												Ux lab gaat veilig en discreet met jouw persoonlijke gegevens om. Op de persoon herleidbare gegevens die we van je vragen zullen gebruikt worden om u van dienst te kunnen zijn. We willen graag weten wie u bent, als je ons UX-Lab gaat gebruiken. We willen ook weten hoe we met jou contact kunnen onderhouden mochten er onverhoopt vragen zijn of mocht er contact nodig zijn. Om het UX-Lab goed te kunnen gebruiken en klaar te zetten voor u met de juiste facilitaire middelen en ondersteuning vragen we je ook naar jouw wensen en het doel van jouw reservering. Uiteraard vragen we om de betaalgegevens waarmee we de zakelijke kant van het huren van het UX-Lab kunnen afwikkelen.
																								</p>
																								<p>
																												<strong>Test en onderzoeksresultaten:</strong><br/>
																												Het Ux lab biedt je de mogelijkheid om opnames te maken van gebruikersinterviews, eyetracks en facial coding, alsmede audio en video opnames van jouw sessies in het Lab. Deze worden voor jouw gemak lokaal opgeslagen. We stellen deze bestanden vervolgens weer aan beschikbaar in een download file of een livestream. Je gaat ermee akkoord dat wij dit doen. Je bepaalt vervolgens op welke wijze je de bestanden wilt ontvangen. De persoon die de boeking doet en afrondt heeft recht op de bestanden, tenzij dit schriftelijk anders is overeengekomen.
																								</p>
																								<p>
																												<strong>Ux lab is niet verantwoordelijk voor:</strong><br/>
																								</p>
																								<ul>
																												<li>
																																Het beschermen en veilig bewaken van informatie die je in ons laboratorium verzamelt van jouw eigen respondenten, medewerkers en betrokkenen
																												</li>
																												<li>
																																Het beschermen en veilig bewaken van op de persoon herleidbare informatie over deelnemers aan jouw sessies
																												</li>
																								</ul>
																								<p>Tevens kunnen we niet verantwoordelijk gesteld worden voor het verkrijgen van akkoordverklaringen omtrent persoonsgegevens van respondenten, testpanelleden die je zelfstandig werft. Je dient hen zelf om permissie te vragen en dit te administreren.</p>
																								<p>
																												<strong>Informatie die je met ons deelt:</strong><br/>
																												Je kunt je registreren voor verschillende aanvullende diensten die Ux lab en Concept7 je bieden. Denk daarbij aan een account zodat je sneller kunt reserveren en jouw historie over het gebruik van UX-Lab kunt terugzien. Denk daarbij ook aan onze Design Thinking Toolkit, onze newsletters en andere kennisbronnen die we voor je beschikbaar stellen. Ook kan je invitaties versturen, jouw reservering in het lab delen en daarbij door jou (als gebruiker) gecreëerde content distribueren. Deze informatie deelt je met ons en maakt het voor ons mogelijk je relevanter en persoonlijker te ondersteunen. Wij zullen dan graag telefonisch, via e-mail of via sociale media met je communiceren. Ook kan het zijn dat je aanvullende diensten hebt afgenomen die we van derden betrekken. Denk hierbij aan de optie om een hotelovernachting te boeken of een lunch of diner te reserveren. Wij zullen indien nodig jouw gegevens verstrekken zodat deze boeking of reservering ook daadwerkelijk voor je gemaakt kan worden. Je geeft ons hiervoor toestemming wanneer je onze services en services van door ons betrokken derden via ons platform (UX-Lab.nl) wenst te gebruiken.
																								</p>
																								<p>
																												<strong>Waar en hoe slaan we informatie op:</strong><br/>
																												Informatie over jou, als booker van het Ux lab, wordt opgeslagen in ons relatiebeheersysteem dat gehost wordt binnen Europa. Testresultaten en onderzoeksopnames worden lokaal in ons Lab opgeslagen. Informatie die we opslaan zullen we voor u bewaren tot u aangeeft dat deze verwijderd mag worden.
																								</p>
																								<p>
																												We behouden ons het recht voor om na 6 maanden je te vragen of de data nog bewaard moet worden en zo nee of we deze data mogen verwijderen dan wel mogen overdragen naar een locatie in jouw beheer. Bij geen reactie binnen 1 maand na dit verzoek,  behouden we ons het recht voor jouw data te verwijderen en vernietigen. Mocht u wensen dat wij langer uw data opslaan dan kunt u dat bij ons aangeven (<a href="mailto:info@uxlab.nl">info@Uxlab.nl</a>). Het kan zijn dat de kosten, van het verlengen van de periode waarin jouw data bij ons opgeslagen moet blijven, zullen worden doorbelast aan jou. Hiervoor krijgt je vooraf een kostenvoorstel, waarop je akkoord kan geven.
																								</p>
																								<p>
																												<strong>Veiligheid</strong><br/>
																												Wij vinden het belangrijk informatie veilig op te slaan en hebben passende maatregelen getroffen om ongeautoriseerde toegang tot jouw informatie te voorkomen. Desondanks is het voor ons onmogelijk om te garanderen dat jouw data niet gehackt kunnen worden. Wij zullen echter in geen geval persoonlijke informatie verkopen of willens en wetens verstrekken aan derden, zonder je daarvoor vooraf, schriftelijk om toestemming te hebben gevraagd.
																								</p>
																								<p>
																												Mocht je vragen hebben over onze dienstverlening, over ons privacy protocol of over onze organisatie neem dan contact op met:
																								</p>
																								<p>
																												Ux lab (van Concept7)<br/>
																												Peizerweg 97<br/>
																												9727 AJ Groningen<br/>
																												Nederland
																								</p>
																								<p>
																												M: <a href="mailto:info@uxlab.nl">info@Uxlab.nl</a>
																								</p>
																				</div>
																</div>
												</div>
								);
				}
}


export default PrivacyPolicy;