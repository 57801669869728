import React from 'react';
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import dateicon from "../assets/images/icons/date.svg";

const DatepickerButton = (props) => (
				<div className={`btn btn-pink ${props.className}`} onClick={props.click}>
								<div className="btn-icon">
												<SVG
																className='btn-icon-svg'
																src={props.icon}
																uniquifyIDs
																cacheGetRequests
												/>
								</div>
								{props.text}
				</div>
);

DatepickerButton.propTypes = {
				className: PropTypes.string,
				click: PropTypes.func,
				icon: PropTypes.string,
				text: PropTypes.string
};

DatepickerButton.defaultProps = {
				className: '',
				click: undefined,
				icon: dateicon,
				text: 'Kies datum'
};

export default DatepickerButton;
