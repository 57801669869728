import React from 'react';
import PropTypes from 'prop-types';
import sidebar from '../assets/images/sidebar.png';

const Sidebar = (props) => (
				<div className="sidebar">
								<img src={sidebar} alt="sidebar" className="sidebar__img img-responsive"/>
								{props.children}
				</div>
);

Sidebar.propTypes = {
				children: PropTypes.node
};

Sidebar.defaultProps = {
				children: undefined
};

export default Sidebar;
