import host from '../assets/images/avatars/host.png';
import instructeur from '../assets/images/avatars/instructeur.png';
import interviewer from '../assets/images/avatars/interviewer.png';
import DTstrateeg from '../assets/images/avatars/DTstrateeg.png';
import CROconsultant from '../assets/images/avatars/CROconsultant.png';
import facilitator from '../assets/images/avatars/facilitator.png';
import lunch from '../assets/images/avatars/lunch.png';
import diner from '../assets/images/avatars/diner.png';
import hotel from '../assets/images/avatars/hotel.png';

export const PRICEPERDAY = 1000;

export const PROFESSIONALS = [
				{
								id: 1,
								img: host,
								title: "Host / hostess",
								description: "Onze expert leid je team door een succesvolle designsprint en helpt mee in de inhoud.",
								priceperhour: 55,
								priceperday: 440,
								selected: false,
				},
				{
								id: 2,
								img: instructeur,
								title: "Instructeur / technisch support",
								description: "Onze expert leid je team door een succesvolle designsprint en helpt mee in de inhoud.",
								priceperhour: 100,
								priceperday: 800,
								selected: false,
				},
				{
								id: 3,
								img: interviewer,
								title: "Observator / interviewer",
								description: "Onze expert leid je team door een succesvolle designsprint en helpt mee in de inhoud.",
								priceperhour: 100,
								priceperday: 800,
								selected: false,
				},
				{
								id: 4,
								img: DTstrateeg,
								title: "Design Thinking strateeg (gecertificeerd)",
								description: "Onze expert leid je team door een succesvolle designsprint en helpt mee in de inhoud.",
								priceperhour: 125,
								priceperday: 1000,
								selected: false,
				},
				{
								id: 5,
								img: CROconsultant,
								title: "Digital marketing strateeg / CRO consultant",
								description: "Onze expert leid je team door een succesvolle designsprint en helpt mee in de inhoud.",
								priceperhour: 125,
								priceperday: 1000,
								selected: false,
				},
				{
								id: 6,
								img: facilitator,
								title: "Facilitator",
								description: "Onze expert leid je team door een succesvolle designsprint en helpt mee in de inhoud.",
								priceperhour: 125,
								priceperday: 800,
								selected: false,
				}
];

export const OPTIONS = [
				{
								id: 1,
								img: lunch,
								title: "Lunch",
								description: "Lunch € 12 per persoon",
								priceperday: 12,
								selected: false,
				},
				{
								id: 2,
								img: diner,
								title: "Diner reservering (3-gangen)",
								description: "Diner € 45,- per persoon",
								priceperday: 45,
								selected: false,
				},
				{
								id: 3,
								img: hotel,
								title: "Hotelovernachting in 4 sterren hotel",
								description: "€ 100,- per nacht per persoon (o.b.v. 1 persoonskamer)",
								priceperday: 100,
								selected: false,
				},
];

