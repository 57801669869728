import React from 'react'
import { NavLink} from 'react-router-dom';
import classnames from 'classnames';

export default class Cookies extends React.Component{
    constructor(){
        super();
        
        this.state = {
            cookieName : 'cookiePopup',
            expiresIn : 365,
            cookieSet : false
        }
    }
    /**
     * check for the cookie on init/mounting
     */
    componentWillMount(){
        //-- lets overright several settings with any props that are set externally
        if (this.props.expires) this.setState({expiresIn: this.props.expires});
        //-- now check for the cookie
        this._checkCookie();
    }
    /**
     * Close the pop up message on click event action
     */
    closeMessage(evt){
        this.setState({cookieSet : true})
    }
    /**
     * main reactjs render method
     * @return {object}
     */
    render(){
        /**
         * enable the setting of the visual class(s)
         */
        var classes = classnames({
            'cookies': true,
            'dismiss': this.state.cookieSet
        });
        return (
            <div className={classes}>
                <div className="cookies__title">Cookiemelding</div>
                <p className="cookies__text">
                    Makkelijk boeken op Uxlab.nl?<br/>
                    Accepteer dan onze cookies. Om voor jou de beste gebruikerservaring te kunnen bieden hebben we cookies gebruikt. Zo kunnen we zorgen dat je makkelijk kan boeken, jouw gegevens maar 1 keer hoeft in te vullen en snel klaar bent op onze site.
                </p>
                <p className="cookies__text">Meer weten over onze <NavLink className="cookies__text-link" to="privacy-policy">privacy policy</NavLink> en <NavLink className="cookies__text-link" to="algemene-voorwaarden">algemene voorwaarden</NavLink>.</p>
                <div className="cookies__btn-wrapper">
                    <NavLink className="cookies__btn btn btn-pink" to="#" onClick={this._setCookie.bind(this)}>Accepteer</NavLink>
                    <NavLink
                        className="cookies__link" to="#" onClick={this.closeMessage.bind(this)}>Accepteer niet</NavLink>
                </div>
            </div>
        )
    }
    
    /**
     * check for a existing cookie
     */
    _checkCookie() {
        //-- get the required cookie
        let cookie = this._getCookie();
        //-- check if we do have a cookie already set
        if (cookie !=="") {this.setState({cookieSet : true}); return;}
        //-- if not lets display message and set it
    }
    /**
     * set the cookie
     */
    _setCookie() {
        var d = new Date(),
            cvalue = this._setCookieValue();
        d.setTime(d.getTime() + (this.state.expiresIn*24*60*60*1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = this.state.cookieName + "=" + cvalue + "; " + expires;
        this.closeMessage();
    }
    /**
     * set the cookie value
     * @return {string}
     */
    _setCookieValue() {
        var a = () => { return (((1 + Math.random()) * 65536) | 0).toString(16).substring(1)};
        return (a() + a() + "-" + a() + "-" + a() + a() + a())
    }
    /**
     * get the cookies and searh for ours
     * @return {string}
     */
    _getCookie() {
        var name = this.state.cookieName + "=";
        var ca = document.cookie.split(';');
        for(let i=0; i<ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length,c.length);
        }
        return "";
    }
    
}