import React from 'react';
import MediaQuery from 'react-responsive';

// Screen sizes media
//
// xs <576px
// sm ≥576px
// md ≥768px
// lg ≥992px
// xl ≥1200px

const Desktop = ({children}) => <MediaQuery minWidth={1024}>{children}</MediaQuery>;

const Mobile = ({children}) => <MediaQuery maxWidth={1023}>{children}</MediaQuery>;

export {Desktop, Mobile};
