import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import {selectedOptions, selectedProfessionals, optionsPrice} from "../actions";
import {PriceFormat} from '../components/PriceFormat';
import plusicon from '../assets/images/icons/plus.svg';

class Recommendedpicker extends React.Component {
				constructor(props) {
								super(props);
								
								this.state = {
												OPTIONS: this.props.cookies.get('OPTIONS') || this.props.selectedOptions.OPTIONS,
												PROFESSIONALS: this.props.cookies.get('PROFESSIONALS') || this.props.selectedProfessionals.PROFESSIONALS,
												optionsPrice: this.props.cookies.get('OPTIONSPRICE') || this.props.optionsPrice,
												initialDays: this.props.selectedDays,
												extraProfs: false,
												extraOptions: true
								};
								
								this.onAddProfessional = this.onAddProfessional.bind(this);
								this.onAddOptions = this.onAddOptions.bind(this);
								this.IncrementPersons = this.IncrementPersons.bind(this);
								this.DecreasePersons = this.DecreasePersons.bind(this);
								this.IncrementDays = this.IncrementDays.bind(this);
								this.DecreaseDays = this.DecreaseDays.bind(this);
								
				}
				
				// componentWillUpdate(nextProps, nextState, nextContext) {
				// 				this.saveData('PROFESSIONALS', this.state.PROFESSIONALS);
				// 				this.saveData('OPTIONS', this.state.OPTIONS);
				// }
				
				componentDidMount() {
								const {cookies} = this.props;
								if (cookies.get('PROFESSIONALS')) {
												this.props.selectProfessionals(cookies.get('PROFESSIONALS'))
								};
								if (cookies.get('OPTIONS')) {
												this.props.selectOptions(cookies.get('OPTIONS'))
								};
				}
				
				componentWillUpdate(nextProps, nextState, nextContext) {
								if (this.state.PROFESSIONALS !== this.props.selectedProfessionals.PROFESSIONALS ) {
												this.setState({ PROFESSIONALS: this.props.selectedProfessionals.PROFESSIONALS});
												this.saveData('PROFESSIONALS', this.props.selectedProfessionals.PROFESSIONALS);
												this.totalOptions();
								}
								if (this.state.OPTIONS !== this.props.selectedOptions.OPTIONS ) {
												this.setState({ OPTIONS: this.props.selectedOptions.OPTIONS});
												this.saveData('OPTIONS', this.props.selectedOptions.OPTIONS);
												this.totalOptions();
								}
				}
				
				saveData (type, data) {
								const {cookies} = this.props;
								if (cookies.get('cookiePopup')) {
												cookies.set(type, JSON.stringify(data))
								};
				}
				
				totalOptions = () => {
								const {cookies} = this.props;
								const ALLOPTIONS = this.props.selectedProfessionals.PROFESSIONALS.concat(this.props.selectedOptions.OPTIONS);
								const SELECTED_OPTIONS = ALLOPTIONS
								.filter(item => (item.selected !== false))
								.map((item) => item.totalprice);
								const PRICEOPTIONS = SELECTED_OPTIONS.reduce((a, b) => {
												return a + b}, 0);
								this.setState({
												PRICE_OPTIONS: PRICEOPTIONS}
								);
								this.props.optionsPrice(PRICEOPTIONS);
								if (cookies.get('cookiePopup')) {
												cookies.set('OPTIONSPRICE', PRICEOPTIONS)
								};
				};
				
				onAddProfessional = ({title, selectedDays}, totalPrice) => {
								this.setState(prevState => ({
												PROFESSIONALS: prevState.PROFESSIONALS.map(
																obj => (obj.title === title ? Object.assign(obj, { selected: true, selectedDays: selectedDays, totalprice: totalPrice }) : obj)
												)
								}));
								this.props.selectProfessionals(this.state.PROFESSIONALS);
				}
				
				onAddOptions({title, selectedDays}, totalPrice) {
								this.setState(prevState => ({
												OPTIONS: prevState.OPTIONS.map(
																obj => (obj.title === title ? Object.assign(obj, { selected: true, selectedDays: selectedDays, totalprice: totalPrice }) : obj)
												)
								}));
								this.props.selectOptions(this.state.OPTIONS);
				}
				
				renderAction({action, title, selectedDays}, totalPrice) {
								switch (action) {
												case 'professionals':
																return <div onClick={()=>this.onAddProfessional({title, selectedDays}, totalPrice)} className="btn btn-blue"><SVG
																				className='recommended__block__add'
																				src={plusicon}
																				uniquifyIDs
																				cacheGetRequests
																/>Toevoegen</div>;
												case 'options':
																return <div onClick={()=>this.onAddOptions({title, selectedDays}, totalPrice)} className="btn btn-blue"><SVG
																				className='recommended__block__add'
																				src={plusicon}
																				uniquifyIDs
																				cacheGetRequests
																/>Toevoegen</div>;
												default:
																return null;
								}
				}
				
				IncrementPersons({title, persons}){
								this.setState(prevState => ({
												OPTIONS: prevState.OPTIONS.map(
																obj => (obj.title === title ? Object.assign(obj, { persons: persons + 1,}) : obj)
												)
								}));
				}
				
				DecreasePersons({title, persons}){
								this.setState(prevState => ({
												OPTIONS: prevState.OPTIONS.map(
																obj => (obj.title === title ? Object.assign(obj, { persons: persons - 1,}) : obj)
												)
								}));
				}
				
				IncrementDays({action, title, selectedDays}){
								const {initialDays} = this.state;
								if(selectedDays >= initialDays ) {
								
								}
								else {
												switch (action) {
																case 'professionals':
																				this.setState(prevState => ({
																								PROFESSIONALS: prevState.PROFESSIONALS.map(
																												obj => (obj.title === title ? Object.assign(obj, { selectedDays: selectedDays + 1,}) : obj)
																								)
																				}));
																				break
																case 'options':
																				this.setState(prevState => ({
																								OPTIONS: prevState.OPTIONS.map(
																												obj => (obj.title === title ? Object.assign(obj, { selectedDays: selectedDays + 1,}) : obj)
																								)
																				}));
																				break
																default:
																				return null;
												}
								}
				}
				DecreaseDays({action, title, selectedDays}){
								if(selectedDays <= 1) {
								
								}
								else {
												switch (action) {
																case 'professionals':
																				this.setState(prevState => ({
																								PROFESSIONALS: prevState.PROFESSIONALS.map(
																												obj => (obj.title === title ? Object.assign(obj, { selectedDays: selectedDays - 1,}) : obj)
																								)
																				}));
																				break;
																case 'options':
																				this.setState(prevState => ({
																								OPTIONS: prevState.OPTIONS.map(
																												obj => (obj.title === title ? Object.assign(obj, { selectedDays: selectedDays - 1,}) : obj)
																								)
																				}));
																				break;
																default:
																				return null;
												}
								}
				}
				
				showProfs = () => {
								this.setState({
												extraProfs: !this.state.extraProfs,
								});
				};
				
				showOptions = () => {
								this.setState({
												extraOptions: !this.state.extraOptions,
								});
				};
				
				recommendationItem(index, item, img, title, description, price, selectedDays, action, persons) {
								const {initialDays} = this.state;
								return (
												<div key={index} className="recommended__block">
																<div className="recommended__block__header">
																				{img && <img className="recommended__block__avatar" src={img} alt="avatar"/>}
																				{title && <div className="recommended__block__title">{title}</div>}
																</div>
																{description && <div className="recommended__block__description">{description}</div>}
																<div className="recommended__block__summary">
																				<div className="recommended__block__options">
																								<div className="recommended__block__days">
																												<div className="recommended__block__days-label">
																																Aantal dagen:
																												</div>
																												<div className="recommended__block__days-input">
																																<div className={classNames("recommended__block__days-btn", {"recommended__block__days-btn-disabled": selectedDays <= 1})} onClick={()=> this.DecreaseDays({action, title, selectedDays})}>-</div>
																																<div className="recommended__block__days-result">{selectedDays}</div>
																																<div className={classNames("recommended__block__days-btn", {"recommended__block__days-btn-disabled": selectedDays >= initialDays})} onClick={()=> this.IncrementDays({action, title, selectedDays})}>+</div>
																												</div>
																								</div>
																								{action === "options" && <div className="recommended__block__persons">
																												<div className="recommended__block__persons-label">
																																Aantal personen:
																												</div>
																												<div className="recommended__block__days-input">
																																<div className={classNames("recommended__block__days-btn", {"recommended__block__days-btn-disabled": persons <= 1})} onClick={()=> this.DecreasePersons({title, persons})}>-</div>
																																<div className="recommended__block__persons-result">{persons}</div>
																																<div className="recommended__block__persons-btn" onClick={()=> this.IncrementPersons({title, persons})}>+</div>
																												</div>
																								</div>}
																				</div>
																				{price && selectedDays && <div className="recommended__block__price">
																								{PriceFormat(action === "options" ? persons*selectedDays*price : selectedDays*price)}
																				</div>}
																				{this.renderAction({action, title, selectedDays}, (persons*selectedDays*price || selectedDays*price))}
																</div>
												</div>
								)
				}
				
				render() {
								const {recommended, selectedDays} = this.props;
								const {PROFESSIONALS, OPTIONS, extraProfs, extraOptions} = this.state;
								
								return (
												<div className="availability__content__block">
																<div className="recommended__title">Wij raden hierbij aan:</div>
																
																				{PROFESSIONALS.reduce((result, item) => {
																								if (item.title === recommended && item.selected === false) { result =
																												<div className="recommended__block__wrapper">
																																<div className="recommended__block__items recommended__block__items-active">
																																				{PROFESSIONALS
																																				.filter(item => (item.title === recommended && item.selected === false))
																																				.map((item, index) => this.recommendationItem(index, item, item.img, item.title, item.description, item.priceperday, item.selectedDays || selectedDays, 'professionals'))}
																																</div>
																												</div>
																								}
																								return result
																				}, null)}
																				
																				{PROFESSIONALS.reduce((result, item) => {
																								if (item.title !== recommended && item.selected === false) { result =
																												<div className="recommended__block__wrapper">
																																<div className={classNames("recommended__block__extra", {"recommended__block__extra-active": extraProfs})} onClick={this.showProfs}>Bekijk nog meer opties
																																				<SVG
																																				className='recommended__block__extra-icon'
																																				src={plusicon}
																																				uniquifyIDs
																																				cacheGetRequests
																																				/>
																																</div>
																																<div className={classNames("recommended__block__items", {"recommended__block__items-active": extraProfs})}>
																																				{PROFESSIONALS
																																				.filter(item => (item.title !== recommended && item.selected === false))
																																				.map((item, index) => this.recommendationItem(index, item, item.img, item.title, item.description, item.priceperday, item.selectedDays || selectedDays, 'professionals'))}
																																</div>
																												</div>
																												 }
																								return result
																				}, null)}
																				
																				{OPTIONS.reduce((result, item) => {
																								if (item.selected === false) { result =
																												<div className="recommended__block__wrapper">
																																<div className={classNames("recommended__block__extra", {"recommended__block__extra-active": extraOptions})} onClick={this.showOptions}>Bekijk de extra opties
																																				<SVG
																																				className='recommended__block__extra-icon'
																																				src={plusicon}
																																				uniquifyIDs
																																				cacheGetRequests
																																				/>
																																</div>
																																<div className={classNames("recommended__block__items", {"recommended__block__items-active": extraOptions})}>
																																				{OPTIONS
																																				.filter(item => (item.selected === false))
																																				.map((item, index) => this.recommendationItem(index, item, item.img, item.title, item.description, item.priceperday, item.selectedDays || selectedDays, 'options', item.persons || 1))}
																																</div>
																												</div>
																												 }
																								return result
																				}, null)}
																				
												</div>
								);
				}
}

Recommendedpicker.propTypes = {
				recommended: PropTypes.string,
				selectedDays: PropTypes.number
};

Recommendedpicker.defaultProps = {
				recommended: '',
				selectedDays: null
};

const mapStateToProps = (state, ownProps) => ({
				selectedOptions: state.selectedOptions,
				selectedProfessionals: state.selectedProfessionals,
				optionsPrice: state.optionsPrice,
				cookies: ownProps.cookies
});

const mapDispatchToProps = dispatch => bindActionCreators({selectOptions: selectedOptions, selectProfessionals: selectedProfessionals, optionsPrice: optionsPrice }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Recommendedpicker);
