import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SVG from 'react-inlinesvg';
import {selectedOptions, selectedProfessionals, optionsPrice} from "../actions";
import {PriceFormat} from '../components/PriceFormat';
import plusicon from '../assets/images/icons/plus.svg';

class RecommendedItems extends React.Component {
				constructor(props) {
								super(props);
								
								this.state = {
												OPTIONS: this.props.cookies.get('OPTIONS') || this.props.selectedOptions.OPTIONS,
												PROFESSIONALS: this.props.cookies.get('PROFESSIONALS') || this.props.selectedProfessionals.PROFESSIONALS,
												optionsPrice: this.props.cookies.get('OPTIONSPRICE') || this.props.optionsPrice
												
								};
								
								this.onRemoveProfessional = this.onRemoveProfessional.bind(this);
								this.onRemoveOptions = this.onRemoveOptions.bind(this);
								
				}
				
				componentDidMount() {
								const {cookies} = this.props;
								if (cookies.get('PROFESSIONALS')) {
												this.props.selectProfessionals(cookies.get('PROFESSIONALS'))
								};
								if (cookies.get('OPTIONS')) {
												this.props.selectOptions(cookies.get('OPTIONS'))
								};
				}
				
				componentWillUpdate(nextProps, nextState, nextContext) {
								if (this.state.PROFESSIONALS !== this.props.selectedProfessionals.PROFESSIONALS ) {
												this.setState({ PROFESSIONALS: this.props.selectedProfessionals.PROFESSIONALS});
												this.saveData('PROFESSIONALS', this.props.selectedProfessionals.PROFESSIONALS);
												this.totalOptions();
								}
								if (this.state.OPTIONS !== this.props.selectedOptions.OPTIONS ) {
												this.setState({ OPTIONS: this.props.selectedOptions.OPTIONS});
												this.saveData('OPTIONS', this.props.selectedOptions.OPTIONS);
												this.totalOptions();
								}
				}
				
				saveData (type, data) {
								const {cookies} = this.props;
								if (cookies.get('cookiePopup')) {
												cookies.set(type, JSON.stringify(data))
								};
				}
				
				totalOptions = () => {
								const {cookies} = this.props;
								const ALLOPTIONS = this.props.selectedProfessionals.PROFESSIONALS.concat(this.props.selectedOptions.OPTIONS);
								const SELECTED_OPTIONS = ALLOPTIONS
								.filter(item => (item.selected !== false))
								.map((item) => item.totalprice);
								const PRICEOPTIONS = SELECTED_OPTIONS.reduce((a, b) => {
												return a + b}, 0);
								this.setState({
												PRICE_OPTIONS: PRICEOPTIONS}
								);
								this.props.optionsPrice(PRICEOPTIONS);
								if (cookies.get('cookiePopup')) {
												cookies.set('OPTIONSPRICE', PRICEOPTIONS)
								};
				};
				
				onRemoveProfessional({title}) {
								this.setState(prevState => ({
												PROFESSIONALS: prevState.PROFESSIONALS.map(
																obj => (obj.title === title ? Object.assign(obj, { selected: false}) : obj)
												)
								}));
								this.props.selectProfessionals(this.state.PROFESSIONALS);
				}
				
				onRemoveOptions({title}) {
								this.setState(prevState => ({
												OPTIONS: prevState.OPTIONS.map(
																obj => (obj.title === title ? Object.assign(obj, { selected: false}) : obj)
												)
								}));
								this.props.selectOptions(this.state.OPTIONS);
								
				}
				
				renderAction({action, title}) {
								switch (action) {
												case 'professionals':
																return <div onClick={()=>this.onRemoveProfessional({title})} className="availability__item__btn"><SVG
																				className='availability__item__btn-icon'
																				src={plusicon}
																				uniquifyIDs
																				cacheGetRequests
																/></div>;
												case 'options':
																return <div onClick={()=>this.onRemoveOptions({title})} className="availability__item__btn"><SVG
																				className='availability__item__btn-icon'
																				src={plusicon}
																				uniquifyIDs
																				cacheGetRequests
																/></div>;
												default:
																return null;
								}
				}
				
				recommendationItem(index, item, img, title, description, price, selectedDays, action, persons) {
								return (
												<div key={index} className="availability__item">
																{img && <img className="availability__item__avatar" src={img} alt="avatar"/>}
																{title && <div className="availability__item__text"><div className="availability__item__text__items">{this.renderAction({action, title})} <div>{title} ({selectedDays} {selectedDays > 1 ? 'dagen' : 'dag'}){action === "options" && <div className="availability__item__persons">({persons} {persons > 1 ? 'personen' : 'persoon'})</div>}</div></div>
																</div>}
																{price && selectedDays && <span className="availability__item__incl">
																				{PriceFormat(action === "options" ? persons*selectedDays*price : selectedDays*price)}
																</span>}
												</div>
								)
				}
				
				render() {
								const {selectedDays} = this.props;
								const {PROFESSIONALS, OPTIONS} = this.state;
								const DATA = PROFESSIONALS.concat(OPTIONS);
								return (
												[
																DATA.reduce((result, item, index) => {
																				if (item.selected !== false) { result =
																								<div className="availability__item__title" key={index}/>
																				}
																				return result
																}, null),
												PROFESSIONALS
												.filter(item => (item.selected !== false))
												.map((item, index) => this.recommendationItem(index, item, item.img, item.title, item.description, item.priceperday, item.selectedDays || selectedDays, 'professionals')),
												
												OPTIONS
												.filter(item => (item.selected !== false))
												.map((item, index) => this.recommendationItem(index, item, item.img, item.title, item.description, item.priceperday, item.selectedDays || selectedDays, 'options', item.persons || 1))
												
								]);
				}
}

RecommendedItems.propTypes = {
				recommended: PropTypes.string,
				selectedDays: PropTypes.number,
};

RecommendedItems.defaultProps = {
				recommended: '',
				selectedDays: null
};

const mapStateToProps = (state, ownProps) => ({
				selectedOptions: state.selectedOptions,
				selectedProfessionals: state.selectedProfessionals,
				optionsPrice: state.optionsPrice,
				cookies: ownProps.cookies
});

const mapDispatchToProps = dispatch => bindActionCreators({selectOptions: selectedOptions, selectProfessionals: selectedProfessionals, optionsPrice: optionsPrice}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedItems);
