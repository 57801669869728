import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';
import SVG from 'react-inlinesvg';
import arrowlefticon from '../assets/images/icons/arrow-left.svg';

const Header = (props) => (
				<header className={classnames("header", {"header-sticky": props.prevScrollpos > 0})}>
								<div className="header-back" onClick={props.history.goBack}>
												<SVG
																className='header-back-icon'
																src={arrowlefticon}
																uniquifyIDs
																cacheGetRequests
												/>
												Terug
								</div>
								{props.headerTitle && <div className="header-title">{props.headerTitle}</div>}
				</header>
);

Header.propTypes = {
				headerTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
				
};

Header.defaultProps = {
				headerTitle: '',
};

export default withRouter(Header);
