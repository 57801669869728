import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import moment from "moment"
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {selectedDate} from "../actions";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/nl';
import 'moment-weekday-calc';

import {DayPickerRangeController} from 'react-dates';
import {START_DATE, END_DATE} from 'react-dates/constants';

import arrowrighticon from "../assets/images/icons/arrow-right.svg";


class Datepicker extends React.Component {
				constructor(props) {
								super(props);
								
								this.state = {
												focusedInput: END_DATE,
												startDate: this.props.cookies.get('selectedStartDate') ? moment(new Date(this.props.cookies.get('selectedStartDate'))) : this.props.selectedDate.startDate,
												endDate: this.props.cookies.get('selectedEndDate') ? moment(new Date(this.props.cookies.get('selectedEndDate'))) : this.props.selectedDate.endDate
								};
								
								this.onDatesChange = this.onDatesChange.bind(this);
								this.onFocusChange = this.onFocusChange.bind(this);
				}
				
				onDatesChange({startDate, endDate}) {
								const {selectDate, cookies} = this.props;
								this.setState({startDate, endDate: endDate ? endDate : startDate});
								selectDate(moment(new Date(startDate)), (endDate ? moment(new Date(endDate)) : moment(new Date(startDate))), (moment().isoWeekdayCalc({
												rangeStart: startDate,
												rangeEnd: endDate,
												weekdays: [1, 2, 3, 4, 5]
								}) === 0 ? 1 : moment().isoWeekdayCalc({
												rangeStart: startDate,
												rangeEnd: endDate,
												weekdays: [1, 2, 3, 4, 5]
								})));
								if (cookies.get('cookiePopup')) {
												cookies.set('selectedStartDate', moment(new Date(startDate)), { path: '/' });
												cookies.set('selectedEndDate', (endDate ? moment(new Date(endDate)) : moment(new Date(startDate))), { path: '/' });
												cookies.set('totalDays', (moment().isoWeekdayCalc({
																rangeStart: startDate,
																rangeEnd: endDate,
																weekdays: [1, 2, 3, 4, 5]
												}) === 0 ? 1 : moment().isoWeekdayCalc({
																rangeStart: startDate,
																rangeEnd: endDate,
																weekdays: [1, 2, 3, 4, 5]
												})), { path: '/' })
								}
								;
				}
				
				onFocusChange(focusedInput) {
								this.setState({
												// Force the focusedInput to always be truthy so that dates are always selectable
												focusedInput: !focusedInput ? START_DATE : focusedInput,
								});
				}
				
				isDayBlocked = momentDate => {
								const dayString = momentDate.format('dd');
								return dayString === 'za' || dayString === 'zo'
				}
				
				render() {
								const {focusedInput, startDate, endDate} = this.state;
								const {numberOfMonths, daySize, btnText} = this.props;
								
								return (
												<div className="datepicker">
																<DayPickerRangeController
																				isDayHighlighted={day => day.isSame(moment(), 'd')}
																				isDayBlocked={this.isDayBlocked}
																				daySize={daySize}
																				isOutsideRange={day => (moment().diff(day) > -1)}
																				noBorder={true}
																				hideKeyboardShortcutsPanel={true}
																				numberOfMonths={numberOfMonths}
																				onDatesChange={this.onDatesChange}
																				onFocusChange={this.onFocusChange}
																				focusedInput={focusedInput}
																				startDate={startDate}
																				endDate={endDate}
																/>
																<div className="btn-availability-wrapper">
																				<NavLink className="btn btn-pink btn-availability" to="/beschikbaar">
																								{btnText}
																								<SVG
																												className='btn-availability-icon'
																												src={arrowrighticon}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																				</NavLink>
																</div>
												</div>
								);
				}
}

Datepicker.propTypes = {
				numberOfMonths: PropTypes.number,
				daySize: PropTypes.number,
				btnText: PropTypes.string
				
};

Datepicker.defaultProps = {
				numberOfMonths: 2,
				daySize: 52,
				btnText: 'Check beschikbaarheid'
};

const mapStateToProps = (state, ownProps) => ({
				selectedDate: state.selectedDate,
				cookies: ownProps.cookies
});

const mapDispatchToProps = dispatch => bindActionCreators({selectDate: selectedDate}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Datepicker);
