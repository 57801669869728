export const totalPrice = (TOTAL_PRICE) => {
				return {
								type: "TOTAL_PRICE",
								payload: TOTAL_PRICE
				}
}

export const optionsPrice = (OPTIONS_PRICE) => {
				return {
								type: "OPTIONS_PRICE",
								payload: OPTIONS_PRICE
				}
}

export const selectedOptions = (OPTIONS) => {
				return {
								type: "OPTIONS_SELECTED",
								payload: {OPTIONS}
				}
}

export const selectedProfessionals = (PROFESSIONALS) => {
				return {
								type: "PROFESSIONALS_SELECTED",
								payload: {PROFESSIONALS}
				}
}

export const selectedPackage = (title, days, price) => {
				return {
								type: "PACKAGE_SELECTED",
								payload: {title: title, days: days, price: price}
				}
}

export const selectedDate = (startDate, endDate, total) => {
				return {
								type: "DATE_SELECTED",
								payload: {startDate: startDate, endDate: endDate, total: total}
				}
}