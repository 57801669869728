import React from 'react';
import logo from "../assets/images/logos/logo.svg";
import logopowered from "../assets/images/logos/logo_powered.svg";
import SVG from "react-inlinesvg";

class Footer extends React.Component {
				
				render() {
								return (
												<div className="footer">
																<div className="footer-container">
																				<div className="footer-logo">
																								<SVG
																												className='footer-logo-svg'
																												src={logo}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																				</div>
																				<div className="footer-address">
																								Peizerweg 97<br/>
																								9727 AJ<br/>
																								Groningen<br/>
																								050 36 00 233<br/>
																								hallo@Uxlab.nl
																				</div>
																				<div className="footer-powered">
																								<a href="https://concept7.nl/" target="_blank" rel="noopener noreferrer">
																												<SVG
																																className='footer-powered-logo-svg'
																																src={logopowered}
																																uniquifyIDs
																																cacheGetRequests
																												/>
																								</a>
																				</div>
																</div>
												</div>
								);
				}
}

export default Footer;
