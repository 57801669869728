import React, {Component} from 'react';
import classnames from 'classnames';
import SVG from "react-inlinesvg";

import Header from './Header';
import Footer from './Footer';
import Cookies from './Cookies';

import front3 from '../assets/images/shapes/hero-front3.svg';
import front4 from '../assets/images/shapes/hero-front4.svg';
import front5 from '../assets/images/shapes/hero-front5.svg';
import back3 from '../assets/images/shapes/hero-back3.svg';

export class Layout extends Component {
				constructor(props) {
								super(props);
								this.state = {
												prevScrollpos: null,
								};
								this.showHeader = true;
								this.headerTitle = '';
								this.showSidebar = true;
				}
				
				componentDidMount() {
								window.addEventListener("scroll", this.handleScroll);
								window.scrollTo(0, 0,);
				}
				
				componentWillUnmount() {
								window.removeEventListener("scroll", this.handleScroll);
				}
				
				handleScroll = () => {
								const currentScrollPos = window.pageYOffset;
								
								this.setState({
												prevScrollpos: currentScrollPos,
								});
				};
				
				renderSidebar() {
								return null;
				}
				
				render() {
								const {prevScrollpos} = this.state;
								return (
												<div className="main">
																{this.showHeader && <Header headerTitle={this.headerTitle} prevScrollpos={prevScrollpos}/>}
																<div className={classnames("content-wrapper", {"content-wrapper-sidebar": this.showSidebar})}>
																				{this.showHeader && <div className="shape-wrapper">
																								<SVG
																												className='shape-layout-1'
																												src={front3}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='shape-layout-2'
																												src={front4}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='shape-layout-3'
																												src={back3}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='shape-layout-4'
																												src={front5}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																				</div>}
																				{this.showSidebar && this.renderSidebar()}
																				<div className="content">
																								{this.renderPageContent(prevScrollpos)}
																				</div>
																</div>
																<Footer/>
																<Cookies />
												</div>
								);
				}
}


export default Layout;
