import React from 'react';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Desktop} from '../components/Responsive';

import logo from '../assets/images/logos/logo.svg';

import front1 from '../assets/images/shapes/hero-front1.svg';
import front2 from '../assets/images/shapes/hero-front2.svg';
import front3 from '../assets/images/shapes/hero-front3.svg';
import front4 from '../assets/images/shapes/hero-front4.svg';
import front5 from '../assets/images/shapes/hero-front5.svg';
import front6 from '../assets/images/shapes/hero-front6.svg';
import front7 from '../assets/images/shapes/hero-front7.svg';
import front8 from '../assets/images/shapes/hero-front8.svg';
import front9 from '../assets/images/shapes/hero-front9.svg';
import front10 from '../assets/images/shapes/hero-front10.svg';
import front11 from '../assets/images/shapes/hero-front11.svg';
import front12 from '../assets/images/shapes/hero-front12.svg';

import back1 from '../assets/images/shapes/hero-back1.svg';
import back2 from '../assets/images/shapes/hero-back2.svg';
import back3 from '../assets/images/shapes/hero-back3.svg';
import back4 from '../assets/images/shapes/hero-back4.svg';
import back5 from '../assets/images/shapes/hero-back5.svg';
import back6 from '../assets/images/shapes/hero-back6.svg';
import back7 from '../assets/images/shapes/hero-back7.svg';
import back8 from '../assets/images/shapes/hero-back8.svg';

class Hero extends React.Component {
				
				render() {
								return (
												<div className="hero">
																<SVG
																				className='hero-logo'
																				src={logo}
																				uniquifyIDs
																				cacheGetRequests
																/>
																{this.props.children}
																<div className="hero-shapes-back">
																				<SVG
																								className='hero-shapes-back-1'
																								src={back1}
																								uniquifyIDs
																								cacheGetRequests
																				/>
																				<SVG
																								className='hero-shapes-back-2'
																								src={back2}
																								uniquifyIDs
																								cacheGetRequests
																				/>
																				<SVG
																								className='hero-shapes-back-3'
																								src={back3}
																								uniquifyIDs
																								cacheGetRequests
																				/>
																				
																				<SVG
																								className='hero-shapes-back-4'
																								src={back4}
																								uniquifyIDs
																								cacheGetRequests
																				/>
																				<Desktop>
																								<SVG
																												className='hero-shapes-back-5'
																												src={back5}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-back-6'
																												src={back6}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-back-7'
																												src={back7}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-back-8'
																												src={back8}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																				</Desktop>
																</div>
																<div className={classnames("hero-shapes-front", {"hero-shapes-front-animate": this.props.animate})}>
																				<SVG
																								className='hero-shapes-front-1'
																								src={front1}
																								uniquifyIDs
																								cacheGetRequests
																				/>
																				<SVG
																								className='hero-shapes-front-2'
																								src={front2}
																								uniquifyIDs
																								cacheGetRequests
																				/>
																				<Desktop>
																								<SVG
																												className='hero-shapes-front-3'
																												src={front3}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-4'
																												src={front4}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-5'
																												src={front5}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-6'
																												src={front6}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-7'
																												src={front7}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-8'
																												src={front8}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-9'
																												src={front9}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-10'
																												src={front10}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-11'
																												src={front11}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																								<SVG
																												className='hero-shapes-front-12'
																												src={front12}
																												uniquifyIDs
																												cacheGetRequests
																								/>
																				</Desktop>
																</div>
												</div>
								);
				}
}

Hero.propTypes = {
				children: PropTypes.node
};

Hero.defaultProps = {
				children: undefined
};

export default Hero;
