import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import {selectedPackage} from "../actions";
import {PACKAGES} from "../data";
import {Desktop, Mobile} from '../components/Responsive';
import {PriceFormat} from '../components/PriceFormat';
import checkicon from '../assets/images/icons/check.svg';

const Responsivewrapper = (props) => (
    [
        <Desktop>
            <div className={props.className} onClick={props.desktopclick}>{props.children}</div>
        </Desktop>,
        <Mobile>
            <div className={props.className} onClick={props.mobileclick}>{props.children}</div>
        </Mobile>
    ]
);

const Configurator = props => (
    PACKAGES.map((item, index) => (
        
        <div key={`${props.uniquekey}-${index}`}
                  className={classNames("col-12 col-sm-6 col-lg-4 cfg__option-wrapper", {"cfg__option-wrapper-extended": props.extended})} >
            <Responsivewrapper desktopclick={props.desktopclick} mobileclick={props.mobileclick} >
            <div
                // className={classNames("cfg__option-table", {"cfg__option-table-active": props.selectedPackage.title === item.title})}
                className={classNames("cfg__option-table", {"cfg__option-table-active": (props.cookies.get('selectedPackageTitle') || props.selectedPackage.title) === item.title})}
                onClick={() => [props.selectPackage(item.title, item.days, item.price), props.cookies.get('cookiePopup') ? (props.cookies.set('selectedPackageTitle', item.title, {path: '/'}), props.cookies.set('selectedPackageDays', item.days, {path: '/'}), props.cookies.set('selectedPackagePrice', item.price, {path: '/'})) : null]}
            >
                <div className="cfg__option-table-title">
                    <div className="cfg__option-table-icon">
                        {item.icon && <SVG
                            className='cfg__option-table-icon-svg'
                            src={item.icon}
                            uniquifyIDs
                            cacheGetRequests
                        />}
                    </div>
                    <div className="cfg__option-table-text">
                        {item.title && item.title}
                    </div>
                    {props.extended ? ((props.cookies.get('selectedPackageTitle') || props.selectedPackage.title) === item.title ?
                        <SVG
                            className='cfg__option-table-active-icon'
                            src={checkicon}
                            uniquifyIDs
                            cacheGetRequests
                        /> : null) : null}
                </div>
                <div className="cfg__option-table-range">
                    {item.days && item.days}{item.days_suffix && item.days_suffix}
                </div>
                <div className="cfg__option-table-price">
                    {item.price && PriceFormat(item.price)}{item.price_suffix && item.price_suffix}
                </div>
            </div>
            </Responsivewrapper>
            {props.extended ?
                <div className="cfg__option-extended">
                    <div className="cfg__option-description">
                        {item.description && item.description}
                    </div>
                    <div className="divider"/>
                    <ul className="checks">
                        {Object.entries(item.list).map(([key, value]) => (
                            value && <li key={key}>{key}</li>
                        ))
                        
                        }
                    </ul>
                </div> : null}
        </div>
    ))

);

Configurator.propTypes = {
    extended: PropTypes.bool,
    click: PropTypes.func
};

Configurator.defaultProps = {
    extended: false,
    click: undefined
};

const mapStateToProps = (state, ownProps) => ({
    selectedPackage: state.selectedPackage,
    cookies: ownProps.cookies
});

const mapDispatchToProps = dispatch => bindActionCreators({selectPackage: selectedPackage}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Configurator);
