import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {totalPrice} from "../actions";
import moment from 'moment';
import SVG from "react-inlinesvg";
import {PACKAGES, PRICEPERDAY} from "../data";
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import {PriceFormat} from '../components/PriceFormat';
import Recommendedpicker from "../components/Recommendedpicker";
import RecommendedItems from "../components/RecommendedItems";
import sprinticon from "../assets/images/icons/sprint.svg";
import dateicon from "../assets/images/icons/date.svg";
import videoicon from "../assets/images/icons/video.svg";
import cateringicon from "../assets/images/icons/catering.svg";
import hosticon from "../assets/images/icons/host.svg";
import peopleicon from "../assets/images/icons/people.svg";
import bon from "../assets/images/bon.png";
import arrowrighticon from "../assets/images/icons/arrow-right.svg";
import {NavLink} from "react-router-dom";

class Availability extends Layout {
    constructor(props) {
        super(props);
        this.state = {
            OPTIONS: this.props.cookies.get('OPTIONS') || this.props.selectedOptions.OPTIONS,
            PROFESSIONALS: this.props.cookies.get('PROFESSIONALS') || this.props.selectedProfessionals.PROFESSIONALS,
            PRICE_OPTIONS: 0,
        };
        this.headerTitle = "Reservering";
    }
    
    componentWillMount() {
        this.priceTotal();
    }
    
    componentDidUpdate(nextProps, nextState, nextContext) {
        this.priceTotal();
    }
    
    priceTotal = () => {
        const {selectedDate, selectedPackage, cookies, optionsPrice, totalPrice} = this.props;
        const PACKAGE = PACKAGES.reduce((result, item) => {
            if (item.title === (cookies.get('selectedPackageTitle') || selectedPackage.title)) {
                result = item
            }
            return result
        }, null);
        const EXTRADAYS = cookies.get('totalDays') ?
            (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days) > 0 ?
                (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days)) * PRICEPERDAY + 200 :
                null) :
            (selectedDate.total - selectedPackage.days > 0 ? (selectedDate.total - selectedPackage.days) * PRICEPERDAY + 200 : null);
        const TOTAL = PACKAGE.price + optionsPrice + EXTRADAYS
        
        totalPrice(TOTAL);
        if (cookies.get('cookiePopup')) {
            cookies.set('TOTALPRICE', TOTAL)
        }
        ;
    };
    
    renderDates() {
        const {selectedDate, cookies} = this.props;
        return (
            (cookies.get('selectedStartDate') && cookies.get('selectedEndDate')) ?
                (moment(cookies.get('selectedStartDate')).format('DD MMMM') === moment(cookies.get('selectedEndDate')).format('DD MMMM') ?
                    moment(cookies.get('selectedStartDate')).format('DD MMMM') :
                    `${moment(cookies.get('selectedStartDate')).format('DD MMMM')} - ${moment(cookies.get('selectedEndDate')).format('DD MMMM')}`) :
                (moment(selectedDate.startDate).format('DD MMMM') === moment(selectedDate.endDate).format('DD MMMM') ?
                    moment(selectedDate.startDate).format('DD MMMM') :
                    `${moment(selectedDate.startDate).format('DD MMMM')} - ${moment(selectedDate.endDate).format('DD MMMM')}`)
        )
    }
    
    availabilityItem(icon, text, price, classes) {
        return (
            <div className={`availability__item ${classes}`}>
                {icon && <SVG
                    className='availability__item__icon'
                    src={icon}
                    uniquifyIDs
                    cacheGetRequests
                />}
                {text && <span className="availability__item__text">{text}</span>}
                {price && <span className="availability__item__price">{price}</span>}
                {!price && <span className="availability__item__incl">inclusief</span>}
            </div>
        )
    }
    
    renderSidebar() {
        const {selectedPackage, cookies} = this.props;
        return (
            <Sidebar>
                <h2 className="sidebar__title">Ux lab Groningen</h2>
                <div className="sidebar__content">
                    <div className="sidebar__content-item-wrapper">
                        <div className="sidebar__content-item">
                            <div className="sidebar__content-item-title-wrapper">
                                <div className="sidebar__content-item-icon">
                                    <SVG
                                        className='sidebar__content-item-icon-svg'
                                        src={sprinticon}
                                        uniquifyIDs
                                        cacheGetRequests
                                    />
                                </div>
                                <div className="sidebar__content-item-title">
                                    Ux lab <br/>
                                    {cookies.get('selectedPackageTitle') ? (cookies.get('selectedPackageTitle') === 'Ux lab standaard' ? 'Standaard' : cookies.get('selectedPackageTitle')) : (selectedPackage.title === 'UX Lab standaard' ? 'Standaard' : selectedPackage.title)}
                                </div>
                            </div>
                            <div className="sidebar__content-item-date">
                                {this.renderDates()}
                            </div>
                        </div>
                    </div>
                    <div className="sidebar__content-end" style={{backgroundImage: `url('${bon}')`}}/>
                </div>
            </Sidebar>
        );
    }
    
    renderPageContent() {
        const {selectedDate, selectedPackage, cookies, optionsPrice} = this.props;
        const PACKAGE = PACKAGES.reduce((result, item) => {
            if (item.title === (cookies.get('selectedPackageTitle') || selectedPackage.title)) {
                result = item
            }
            return result
        }, null);
        const EXTRADAYS = cookies.get('totalDays') ?
            (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days) > 0 ?
                (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days)) * PRICEPERDAY + 200 :
                null) :
            (selectedDate.total - selectedPackage.days > 0 ? (selectedDate.total - selectedPackage.days) * PRICEPERDAY + 200 : null);
        return (
            <div className="availability content-container">
                <div className="availability__header">
                    <SVG
                        className='availability__header__icon'
                        src={dateicon}
                        uniquifyIDs
                        cacheGetRequests
                    />
                    <span className='availability__header__text'>
																								{this.renderDates()} is het lab beschikbaar!
																				</span>
                    <div className="triangle"/>
                </div>
                <div className="availability__content">
                    <div className="availability__content__block">
                        
                        {this.availabilityItem(PACKAGE.icon, `${PACKAGE.title} (${PACKAGE.days}${PACKAGE.days_suffix})`, PriceFormat(PACKAGE.price), '')}
                        
                        {EXTRADAYS && this.availabilityItem(dateicon, `+${cookies.get('totalDays') ? cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days) : selectedDate.total - selectedPackage.days} ${(cookies.get('totalDays') - (cookies.get('selectedPackageDays')) || selectedPackage.days) || (selectedDate.total - selectedPackage.days) > 1 ? 'extra dagen' : 'extra dag'}`, PriceFormat(EXTRADAYS), '')}
                        
                        {Object.keys(PACKAGE.list).find(key => PACKAGE.list['A/V recording'] === true) &&
                        this.availabilityItem(videoicon, 'A/V Opnames & streaming', false, '')}
                        
                        {Object.keys(PACKAGE.list).find(key => PACKAGE.list['Catering'] === true) &&
                        this.availabilityItem(cateringicon, 'Lunch & catering', false, '')}
                        
                        {this.availabilityItem(hosticon, 'Ontvangst en host', false, '')}
                        
                        {this.availabilityItem(peopleicon, 'Respondenten werven', 'op aanvraag', 'availability__item-request')}
                        
                        <RecommendedItems
                            cookies={cookies}
                            recommended={PACKAGE.recommended}
                            selectedDays={PACKAGE.days >= (parseInt(cookies.get('totalDays')) || selectedDate.total) ? PACKAGE.days : (parseInt(cookies.get('totalDays')) || selectedDate.total)}
                            // professionals={this.props.selectedProfessionals.PROFESSIONALS}
                            // options={this.props.selectedOptions.OPTIONS}
                        />
                    
                    </div>
                    
                    <Recommendedpicker
                        cookies={cookies}
                        recommended={PACKAGE.recommended}
                        selectedDays={PACKAGE.days >= (parseInt(cookies.get('totalDays')) || selectedDate.total) ? PACKAGE.days : (parseInt(cookies.get('totalDays')) || selectedDate.total)}
                    />
                    
                    <div className="availability__content__block availability__summary">
                        <div className="availability__summary__total"><span
                            className="availability__summary__total-label">Totaal</span> {PriceFormat(PACKAGE.price + optionsPrice + EXTRADAYS)}
                        </div>
                        <div className="availability__summary__text">Alle prijzen zijn exclusief btw.</div>
                        <NavLink to="/details" className="btn btn-pink availability__summary__btn">
                            Je gegevens
                            <SVG
                                className='btn-availability-icon'
                                src={arrowrighticon}
                                uniquifyIDs
                                cacheGetRequests
                            />
                        </NavLink>
                    </div>
                
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selectedPackage: state.selectedPackage,
    selectedDate: state.selectedDate,
    selectedOptions: state.selectedOptions,
    selectedProfessionals: state.selectedProfessionals,
    optionsPrice: state.optionsPrice,
    totalPrice: state.totalPrice,
    cookies: ownProps.cookies,
});

const mapDispatchToProps = dispatch => bindActionCreators({totalPrice: totalPrice}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Availability);