import React from 'react';
import { NavLink} from 'react-router-dom';
import Layout from '../components/Layout';
import Sidebar from "../components/Sidebar";

class TermsAgreement extends Layout {
				constructor(props) {
								super(props);
								this.headerTitle = "Algemene voorwaarden";
				}
				
				renderSidebar() {
								return (
												<Sidebar/>
								);
				}
				
				renderPageContent() {
								return (
												<div className="terms content-container">
																<div className="terms__content">
																				<div className="terms__content__block">
																								<h1>
																												ALGEMENE VOORWAARDEN
																								</h1>
																								<p><strong>DEFINITIES</strong></p>
																								<p><strong>Huurder:</strong> de persoon of organisatie die het Ux lab huurt (<NavLink to="/">www.UXlab.nl</NavLink>)<br/>
																								<strong>Verhuurder:</strong> Concept7</p>
																								<p><strong>VOORWAARDEN</strong></p>
																								<ul>
																												<li>De huur van het Ux lab geschiedt op eigen risico en verantwoordelijkheid (schade, diefstal, etc) van de huurder.</li>
																												<li>Eventuele aansprakelijkheid VAN verhuurder gaat tot maximaal het betaalde huurbedrag aan verhuurder voor de desbetreffende boeking.</li>
																												<li>De huurder zal het Ux lab huren voor activiteiten zonder overlastrisico.</li>
																												<li>De activiteiten waarvoor het Ux lab gehuurd wordt, zullen niet in strijd zijn met de grondwet en zullen geen expliciet politieke doelstelling kennen en/of religieuze overtuiging uitdragen.</li>
																												<li>De activiteiten waarvoor het Ux lab gehuurd wordt vertonen geen verwantschap met horeca.</li>
																												<li>De betaling van de factuur dient uiterlijk 24 uur voor gebruikmaking van het Ux lab ontvangen te zijn. Verhuurder behoudt zich het recht voor huurder te weigeren indien betaling niet ontvangen is. Het UX-Lab dient opgeleverd te worden zoals u hem bij aanvang van het gebruik aangetroffen heeft. Het tarief is inclusief schoonmaakkosten.</li>
																												<li>Huurder is aansprakelijk voor alle schade toegebracht aan pand of inventaris in het Ux lab, indien veroorzaakt door huurder of een van de personen die door huurder toegang heeft gekregen tot het UX-Lab gedurende de huur van de vergaderruimte.</li>
																												<li>In het Ux lab is roken niet toegestaan.</li>
																												<li>In het Ux lab is het niet toegestaan om op tafels of stoelen te staan.</li>
																												<li>Huurder is aansprakelijk voor alle schade toegebracht door ontvreemding van inventaris van het Ux lab veroorzaakt door huurder of een van de personen die door huurder toegang heeft gekregen tot het UX-Lab gedurende de huur van het UX-Lab.</li>
																												<li>Verhuurder kan gebruik maken van de mogelijkheid om gedurende de huur van het Ux lab, aanwezig te zijn in de ruimte.</li>
																												<li>Verhuurder heeft het recht om de huur van het Ux lab per direct te beëindigen bij klachten van (geluids)overlast</li>
																												<li>Huurder zal per direct het Ux lab verlaten indien verhuurder dit verzoekt.</li>
																												<li>Op deze voorwaarden is Nederlands recht van toepassing. Alle geschillen zullen worden onderworpen aan de uitspraak van de bevoegde rechter te Groningen.</li>
																								</ul>
																								<p><strong>GEDRAGSREGELS</strong></p>
																								<p>Volgens de milieuwetgeving is de huurder verantwoordelijk voor een rustig betreden en verlaten van het gebouw en zijn directe omgeving.</p>
																								<ul>
																												<li>Personen die aanstoot geven, de orde verstoren, de veiligheid van zichzelf of anderen in gevaar brengen, of handelen in strijd met één of meer voorwaarden waaronder het gebruik is toegestaan, worden door de verhuurder verwijderd uit de accommodatie.</li>
																												<li>Huisdieren, fietsen, skates en skeelers of dergelijk gebruiksmateriaal worden niet toegelaten in het gebouw.</li>
																												<li>Bij gebruik van ruimte dient de huurder aanwezig te zijn. Deze persoon houdt toezicht op de ruimte en de daarin aanwezige personen. Deze verantwoordelijke persoon is verplicht bij het verlaten van de ruimten de toegangsdeuren af te sluiten en zich ervan te overtuigen dat er geen mensen meer binnen zijn.</li>
																												<li>De huurder is niet bevoegd haar recht tot medegebruik over te dragen aan derden.</li>
																								</ul>
																				</div>
																</div>
												</div>
								);
				}
}


export default TermsAgreement;